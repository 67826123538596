<template>
  <div>
    <SectionHeader title="Market demand" title-icon="fal fa-hands-heart" />
    <div class="box">
      <div v-if="desirabilityPercentages">
        <DonutChart
          :series="(breakdown || []).map(({ count }) => count)"
          :labels="(breakdown || []).map(({ label }) => label)"
          total-label="Vehicles"
        />
        <PercentageBreakdown v-bind="{ breakdown }" @show-stock="showStock" />
      </div>
      <TableEmpty v-else text="No data found for your branch" size="small" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getBarValues, getBreakdown } from '../services'
export default {
  name: 'StockviewMarketDemand',
  components: {
    PercentageBreakdown: () => import('./StockviewPercentageBreakdown'),
    TableEmpty: () => import('core/components/TableEmpty'),
    SectionHeader: () =>
      import('core/modules/section-header/components/SectionHeader'),
    DonutChart: () => import('core/components/graph/DonutChart')
  },
  computed: {
    ...mapGetters('stockview/dashboard', [
      'desirabilityCount',
      'desirabilityPercentages'
    ]),
    values() {
      const keyMap = {
        low: 'Low demand',
        medium: 'Medium demand',
        high: 'High demand'
      }
      return getBarValues(this.desirabilityPercentages, keyMap)
    },
    breakdown() {
      const labels = {
        low: 'Low demand',
        medium: 'Medium demand',
        high: 'High demand',
        notAvailable: 'Not available'
      }

      return getBreakdown(
        this.desirabilityCount,
        this.desirabilityPercentages,
        labels
      )
    }
  },
  methods: {
    ...mapActions('stockview/listings', ['reset', 'updateFilters']),
    showStock(identifier) {
      this.reset()

      const type = 'desirability'
      const keyMap = {
        low: { min: 0, max: 33 },
        medium: { min: 34, max: 66 },
        high: { min: 67, max: 100 }
      }
      const filters = [
        { type: 'desirabilityMin', payload: keyMap[identifier].min },
        { type: 'desirabilityMax', payload: keyMap[identifier].max }
      ]

      this.updateFilters({ filters, count: 1, type })

      this.$router.push({ name: 'stockview-stock' })
    }
  }
}
</script>
